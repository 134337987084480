// import nav from "./components/nav";

// nav();

// if (document.querySelector(".js-notification")) {
//   import("./components/notification").then((init) => init.default());
// }

// if (document.querySelector(".js-search")) {
//   import("./components/search").then((init) => init.default());
// }

// if (document.querySelector(".js-tabs")) {
//   import("./components/tabs").then((init) => init.default());
// }

// if (document.querySelector(".js-accordion")) {
//   import("./components/accordion").then((init) => init.default());
// }

// if (document.querySelector(".js-carousel")) {
//   import("./components/carousel").then((init) => init.default());
// }
// if (document.querySelector(".js-grid-carousel")) {
//   import("./components/gridCarousel").then((init) => init.default());
// }

if (document.querySelector(".js-gallery")) {
  import("./components/gallery").then((init) => init.default());
}

// if (document.querySelector("[data-aos]")) {
//   import("./components/aos").then((init) => init.default());
// }
